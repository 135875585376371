const initialState = {
  isLoading: true,
  isLoadingOverview: true,
  error: false,
  error_msg: null,
  operationalData: null,
  overviewData: null,
  surveys: null,
  selectedSurvey: null,
  selectedCycle: null,
  allFilters: [],
};

export const operationalReducer = (state = initialState, action) => {
  switch (action.type) {
    case "DISMISS_ERROR":
      return {
        ...state,
        error: false,
        error_msg: null,
      };
    case "FETCH_OPERATIONAL_START":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_OPERATIONAL_SUCCESS":
      return {
        ...state,
        isLoading: false,
        operationalData: action.payload,
      };
    case "FETCH_OPERATIONAL_FAILURE":
      return {
        ...state,
        isloading: false,
        error: true,
        error_msg: action.payload,
      };
    case "FETCH_OVERVIEW_START":
      return {
        ...state,
        isLoadingOverview: true,
      };
    case "FETCH_OVERVIEW_SUCCESS":
      return {
        ...state,
        isLoadingOverview: false,
        overviewData: action.payload,
      };
    case "FETCH_OVERVIEW_FAILURE":
      return {
        ...state,
        isLoadingOverview: false,
        error: true,
        error_msg: action.payload,
      };
    case "SET_OVERVIEW_FILTER":
      return {
        ...state,

        allFilters: action.payload.allfilters,
      };

    default:
      return state;
  }
};
