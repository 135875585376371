import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
import Cookies from "universal-cookie";
import { connect } from "react-redux";

import { selectedSurvey, selectedCycle } from "../../store/actions/init";
// core components
import AdminNavbar from "../../components/Navbars/AdminNavbar.js";
// import Footer from "components/Footer/Footer.js";
import Sidebar from "../../components/Sidebar/Sidebar";
// import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";

import logo from "../../assets/img/xaneIcon.jpeg";
import routes from "../../routes";
import Dropdown from "../../components/Dropdown/Dropdown";
// const cookies = new Cookies();
var ps;

class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.mainPanel = React.createRef();
    this.state = {
      activeColor: "blue",
      sidebarMini: true,
      opacity: 0,
      sidebarOpened: false,
      selectedSurveyId: null,
      selectedCycle: null,
      isAuthenticated: null,
      routes: null,
    };
  }
  componentDidMount() {
    // if(!cookies.get('x-auth')){
    //   this.props.history.push("/login")
    // }
    let notifyMessage = "Hi, Welcome to Xane AI Analytics";
    let options = {};
    options = {
      place: "tr",
      message: notifyMessage,
      type: "primary",
      icon: "tim-icons icon-satisfied",
      autoDismiss: 7,
    };
    // this.setState({
    //   isAuthenticated: localStorage.getItem('x-auth')
    // })
    // this.refs.notificationAlert.notificationAlert(options); //Notification
    // if (navigator.userAgentData.platform.indexOf("Win") > -1) {
    //   document.documentElement.classList.add("perfect-scrollbar-on");
    //   document.documentElement.classList.remove("perfect-scrollbar-off");
    //   ps = new PerfectScrollbar(this.mainPanel.current);
    //   this.mainPanel.current.addEventListener(
    //     "ps-scroll-y",
    //     this.showNavbarButton
    //   );
    //   let tables = document.querySelectorAll(".table-responsive");
    //   for (let i = 0; i < tables.length; i++) {
    //     ps = new PerfectScrollbar(tables[i]);
    //   }
    // }
    window.addEventListener("scroll", this.showNavbarButton); //Notification
  }
  componentWillUnmount() {
    // if (navigator.userAgentData.platform.indexOf("Win") > -1) {
    //   ps.destroy();
    //   var element = document.getElementById("perfect-scrollbar-on");
    //   element.classList.add("perfect-scrollbar-off");
    //   document.documentElement.className.add("perfect-scrollbar-off");
    //   document.documentElement.classList.remove("perfect-scrollbar-on");
    //   this.mainPanel.current.removeEventListener(
    //     "ps-scroll-y",
    //     this.showNavbarButton
    //   );
    // }
    window.removeEventListener("scroll", this.showNavbarButton);
  }
  componentDidUpdate(e) {
    // if (e.location.pathname !== e.history.location.pathname) {
    //   if (navigator.userAgentData.platform.indexOf("Win") > -1) {
    //     let tables = document.querySelectorAll(".table-responsive");
    //     for (let i = 0; i < tables.length; i++) {
    //       ps = new PerfectScrollbar(tables[i]);
    //     }
    //   }
    //   document.documentElement.scrollTop = 0;
    //   document.scrollingElement.scrollTop = 0;
    //   this.mainPanel.current.scrollTop = 0;
    // }
  }
  shouldComponentUpdate(nextProps) {
    return this.props !== nextProps;
  }
  showNavbarButton = () => {
    // if (
    //   document.documentElement.scrollTop > 50 ||
    //   document.scrollingElement.scrollTop > 50 ||
    //   this.mainPanel.current.scrollTop > 50
    // ) {
    //   this.setState({ opacity: 1 });
    // } else if (
    //   document.documentElement.scrollTop <= 50 ||
    //   document.scrollingElement.scrollTop <= 50 ||
    //   this.mainPanel.current.scrollTop <= 50
    // ) {
    //   this.setState({ opacity: 0 });
    // }
  };

  findRouteIndex = (arr, name) => {
    let index = arr.findIndex((x) => x.name == name);

    arr.splice(index, 1);

    return arr;
  };

  handleRoutes = () => {
    let routings = [...routes];
    let selectedSurvey =
      this.props.init.surveys &&
      this.props.init.surveys.filter(
        (survey) => survey.id === this.props.init.selectedSurvey
      );

    if (
      selectedSurvey &&
      selectedSurvey[0] &&
      selectedSurvey[0].config.tabs.some((e) => e.code === "early_warn") ===
        false
    ) {
      this.findRouteIndex(routings, "Early Warn");
    }
    if (
      selectedSurvey &&
      selectedSurvey[0] &&
      selectedSurvey[0].config.tabs.some((e) => e.code === "osa") === false
    ) {
      this.findRouteIndex(routings, "OSA");
    }
    if (
      selectedSurvey &&
      selectedSurvey[0] &&
      selectedSurvey[0].config.tabs.some((e) => e.code === "orgchart") === false
    ) {
      this.findRouteIndex(routings, "OrgChart");
    }
    if (
      selectedSurvey &&
      selectedSurvey[0] &&
      selectedSurvey[0].config.tabs.some((e) => e.code === "operational") ===
        false
    ) {
      this.findRouteIndex(routings, "Response Rate");
    }
    if (
      selectedSurvey &&
      selectedSurvey[0] &&
      selectedSurvey[0].config.tabs.some((e) => e.code === "questions") ===
        false
    ) {
      this.findRouteIndex(routings, "Questions");
    }
    if (
      selectedSurvey &&
      selectedSurvey[0] &&
      selectedSurvey[0].config.tabs.some((e) => e.code === "people") === false
    ) {
      this.findRouteIndex(routings, "People");
    }
    if (
      selectedSurvey &&
      selectedSurvey[0] &&
      selectedSurvey[0].config.tabs.some((e) => e.code === "nps") === false
    ) {
      this.findRouteIndex(routings, "NPS");
    }
    if (
      selectedSurvey &&
      selectedSurvey[0] &&
      selectedSurvey[0].config.tabs.some((e) => e.code === "actionplan") ===
        false
    ) {
      this.findRouteIndex(routings, "Action Planning");
    }
    if (selectedSurvey && selectedSurvey[0]) {
      return routings;
    }
  };

  getRoutes = (routingsData) => {
    let routes = this.handleRoutes();

    return (
      routes &&
      routes.map((prop, key) => {
        if (prop.collapse) {
          alert("hi");
          return this.getRoutes(prop.views);
        }
        if (prop.layout === "") {
          return (
            <Route
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
              exact
            />
          );
        } else {
          return null;
        }
      })
    );
  };
  getActiveRoute = (routes) => {
    let activeRoute = "XANE AI";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = this.getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.pathname.indexOf(
            routes[i].layout + routes[i].path
          ) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  handleActiveClick = (color) => {
    this.setState({ activeColor: color });
  };
  handleMiniClick = () => {
    document.body.classList.toggle("sidebar-mini");
  };
  toggleSidebar = () => {
    this.setState({
      sidebarOpened: !this.state.sidebarOpened,
    });
    document.documentElement.classList.toggle("nav-open");
  };
  closeSidebar = () => {
    this.setState({
      sidebarOpened: false,
    });
    document.documentElement.classList.remove("nav-open");
  };

  hancleChangeSurvey = (event) => {
    localStorage.setItem("selectedSurvey", event.value);
    this.setState({
      selectedSurveyId: event.value,
    });
    this.props.selectedSurvey(event.value); //saving into redux store
  };

  hancleChangeCycle = (event) => {
    localStorage.setItem("selectedCycle", event.value);
    this.setState({
      selectedCycle: event.value,
    });
    this.props.selectedCycle(event.value);
  };

  render() {
    let CompanyName =
      this.props.init &&
      this.props.init.company &&
      this.props.init.company.company;

    return (
      <div className="wrapper">
        <div className="rna-container">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <div
          className="navbar-minimize-fixed"
          style={{ opacity: this.state.opacity }}
        >
          <button
            className="minimize-sidebar btn btn-link btn-just-icon"
            onClick={this.handleMiniClick}
          >
            <i className="tim-icons icon-align-center visible-on-sidebar-regular text-muted" />
            <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini text-muted" />
          </button>
        </div>
        <Sidebar
          {...this.props}
          routes={
            this.handleRoutes() &&
            this.handleRoutes().filter(
              (route) => route.name !== "Create Action Plan"
            )
          }
          activeColor={this.state.activeColor}
          logo={{
            outterLink: "#",
            text: CompanyName,
            imgSrc: logo,
          }}
          closeSidebar={this.closeSidebar}
        />
        <div
          className="main-panel"
          ref={this.mainPanel}
          data={this.state.activeColor}
        >
          <AdminNavbar
            {...this.props}
            handleMiniClick={this.handleMiniClick}
            brandText={this.getActiveRoute(routes)}
            sidebarOpened={this.state.sidebarOpened}
            toggleSidebar={this.toggleSidebar}
          />

          <div
            style={{
              width: "30%",
              position: "absolute",
              right: "35px",
              top: "75px",
              zIndex: 100,
              display: "flex",
            }}
          >
            <Dropdown
              style={{
                // width: "0%",
                marginLeft: "10px !important",
              }}
              surveys={this.props.init.surveys}
              selectedSurvey={this.props.init.selectedSurvey}
              cycle={false}
              hancleChangeSurvey={this.hancleChangeSurvey}
            />

            <Dropdown
              surveys={this.props.init.surveys}
              selectedCycle={this.props.init.selectedCycle}
              cycle={true}
              hancleChangeCycle={this.hancleChangeCycle}
              selectedSurvey={this.props.init.selectedSurvey}
            />
          </div>
          <Switch>
            {this.getRoutes(routes)}
            {/* <Redirect from="/" to="/Operational"  /> */}
          </Switch>
          {/* {// we don't want the Footer to be rendered on full screen maps page
          this.props.location.pathname.indexOf("full-screen-map") !==
          -1 ? null : (
            <Footer fluid />
          )} */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  init: state.init,
});

const mapDispatchToProps = (dispatch) => ({
  selectedSurvey: (surveyId) => dispatch(selectedSurvey(surveyId)),
  selectedCycle: (cycle) => dispatch(selectedCycle(cycle)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
