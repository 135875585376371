import { requestApi } from "../../config/apiHandler";



export const fetchInitSuccess = (data) => {
  return {
    type: "FETCH_INIT_SUCCESS",
    payload: {
      user: data.user,
      company: data.company,
      surveys: data.surveys,
      selectedSurvey: localStorage.getItem("selectedSurvey")? Number(localStorage.getItem("selectedSurvey")): data.surveys[0].id, // for initial state
      selectedCycle: localStorage.getItem("selectedCycle")?Number(localStorage.getItem("selectedCycle")): data.surveys[0].cycles[0].cycle  // for initial state
    },
  };
};

export const selectedSurvey = (surveyId) => {
  
  return {
    type: "SET_SELECTED_SURVEY",
    payload: surveyId
  };
};
export const selectedCycle = (cycle) => {
  
  return {
    type: "SET_SELECTED_CYCLE",
    payload: cycle
  };
};

export const fetchInitStarted = () => {
  return {
    type: "FETCH_INIT_START",
  };
};

export const FetchInit = () => {
  return async (dispatch) => {
    dispatch(fetchInitStarted());
    const response = await requestApi("/dashboard/config", "GET");
    if (response.code == "success") {
      return dispatch(fetchInitSuccess(response.data));;

    } else {
      return dispatch(fetchInitFailure(response.message));
    }
  };
};

export const fetchInitFailure = (error) => {
  return {
    type: "FETCH_INIT_FAILURE",
    payload: error,
  };
};
export const dismissError = () => {
  return {
    type: "DISMISS_ERROR",
  };
};