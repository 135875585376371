const initialState = {
    isLoading: true,
    isLoadingUserThemes: true,
    isLoadingManager: true,
    isLoadingManagerTheme: true,
    error: false,
    error_msg: null,
    usersData: null,
    usersThemeData: null,
    managerData: null,
    managerThemeData: null,
    surveys: null,
    selectedSurvey: null,
    selectedCycle: null,
    allFilters: [],
  };
  
  export const peopleReducer = (state = initialState, action) => {
    switch (action.type) {
      case "DISMISS_ERROR":
        return {
          ...state,
          error: false,
          error_msg: null,
        };
      case "FETCH_USERS_START":
        return {
          ...state,
          isLoading: true,
        };
      case "FETCH_USERS_SUCCESS":
        return {
          ...state,
          isLoading: false,
          usersData: action.payload,
        };
      case "FETCH_USERS_FAILURE":
        return {
            ...state,
            isLoading:false,
            error: true,
            error_msg: action.payload,
          
        };
      case "FETCH_USERS_THEME_START":
        return {
          ...state,
          isLoadingUserThemes: true,
        };
      case "FETCH_USERS_THEME_SUCCESS":
        return {
          ...state,
          isLoadingUserThemes: false,
          usersThemeData: action.payload,
        };
      case "FETCH_USERS_THEME_FAILURE":
        return {
            ...state,
            isLoadingUserThemes:false,
            error: true,
            error_msg: action.payload,
          
        };
      case "FETCH_MANAGER_START":
        return {
          ...state,
          isLoadingManager: true,
        };
      case "FETCH_MANAGER_SUCCESS":
        return {
          ...state,
          isLoadingManager: false,
          managerData: action.payload,
        };
      case "FETCH_MANAGER_FAILURE":
        return {
            ...state,
            isLoadingManager:false,
            error: true,
            error_msg: action.payload,
          
        };
      case "FETCH_MANAGER_THEME_START":
        return {
          ...state,
          isLoadingManagerTheme: true,
        };
      case "FETCH_MANAGER_THEME_SUCCESS":
        return {
          ...state,
          isLoadingManagerTheme: false,
          managerThemeData: action.payload,
        };
      case "FETCH_MANAGER_THEME_FAILURE":
        return {
            ...state,
            isLoadingManagerTheme:false,
            error: true,
            error_msg: action.payload,
          
        };
        case "SET_PEOPLE_FILTER":
         
          return {
              ...state,
              allFilters: action.payload.allfilters
          };
  
      default:
        return state;
    }
  };
  