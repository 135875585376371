import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { createBrowserHistory } from "history";
import { BrowserRouter } from "react-router-dom";
import { Chart } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

import { Provider } from "react-redux";
import { configureStore } from "./store/store";

import "./assets/css/nucleo-icons.css";
import "react-notification-alert/dist/animate.css";
import "./assets/css/black-dashboard-pro-react.css";
import "./styles/customStyle.css";

const hist = createBrowserHistory();
export const store = configureStore();
// Register the plugin to all charts:
Chart.register(ChartDataLabels);
// Change default options for ALL charts
Chart.defaults.set("plugins.datalabels", {
  color: "#fff",
});

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter history={hist}>
      <App hist={hist} />
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
