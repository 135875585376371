const initialState = {
  isLoading: true,
  isLoadingQuestion: true,
  error: false,
  error_msg: null,
  questionsData: null,
  themesData: null,
  themesbycycle: null,
  opjectiveCount: null,
  subjectiveResponses: null,
  followupResponses: null,
  surveys: null,
  allFilters: [],
  selectedSurvey: null,
  selectedCycle: null,
  questionsfilteredByUserId: [],
};

export const questionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "DISMISS_ERROR":
      return {
        ...state,
        error: false,
        error_msg: null,
      };
    case "FETCH_QUESTIONS_START":
      return {
        ...state,
        isLoadingQuestion: true,
      };
    case "FETCH_QUESTIONS_SUCCESS":
      return {
        ...state,
        isLoadingQuestion: false,
        questionsData: action.payload,
      };
    case "FETCH_QUESTIONS_FAILURE":
      return {
        ...state,
        isLoadingQuestion: false,
        error: true,
        error_msg: action.payload,
      };
    case "FETCH_QUESTIONS_BY_USER_ID_START":
      return {
        ...state,
        isLoadingQuestion: true,
      };
    case "FETCH_QUESTIONS_BY_USER_ID_SUCCESS":
      return {
        ...state,
        isLoadingQuestion: false,
        questionsfilteredByUserId: action.payload,
      };
    case "FETCH_QUESTIONS_BY_USER_ID_FAILURE":
      return {
        ...state,
        isLoadingQuestion: false,
        error: true,
        error_msg: action.payload,
      };
    case "FETCH_THEMES_START":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_THEMES_SUCCESS":
      return {
        ...state,
        isLoading: false,
        themesData: action.payload.data,
        themesbycycle: action.payload.themesbycycle,
        opjectiveCount: action.payload.opjectiveCount,
        subjectiveResponses: action.payload.subjectiveResponses,
        followupResponses: action.payload.followupResponses,
      };
    case "FETCH_THEMES_FAILURE":
      return {
        ...state,
        isloading: false,
        error: true,
        error_msg: action.payload,
      };
    case "SET_QUESTION_FILTER":
      return {
        ...state,
        allFilters: action.payload.allfilters,
      };
    default:
      return state;
  }
};
