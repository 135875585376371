import Cookies from "universal-cookie"
import { requestApi } from "../../config/apiHandler";
const cookies = new Cookies();

export const restoreToken = (token) => {
  
  return {
    type: "RESTORE_TOKEN",
    payload: {
      token: token
    },
  };
};

export const signInSuccess = (token) => {
  
  return {
    type: "SIGN_IN_SUCCESS",
    payload: {
      token: token
    },
  };
};

export const signInStarted = () => {
  return {
    type: "SIGN_IN_STARTED",
  };
};

export const signIn = (email, password) => {
  return async (dispatch) => {
    dispatch(signInStarted());

    let token = null;
    let params = {};
    params.email = email;
    params.password = password;
    const response = await requestApi("/auths/login", "POST", params);
    
    if (response.code == "success") {
      token = response.data.session.token;
      // localStorage.setItem("x-auth", JSON.stringify(token));
      cookies.set("x-auth", token);
      return dispatch(signInSuccess(token));
    } else {
      return dispatch(signInFailure(response.message));
    }
  };
};

export const signInFailure = (error) => {
  return {
    type: "SIGN_IN_FAILURE",
    payload: error,
  };
};

export const signOut = () => {
  return async (dispatch) => {
    const response = await requestApi("/auths/logout", "PUT", {});
    try {
      if (response.code == "success" || response.code == "authn_fail") {
        await cookies.remove('x-auth');
        await localStorage.clear()
        return dispatch({ type: "SIGN_OUT" });
      }
    } catch (e) {
      console.error(e);
    }
  };
};


export const dismissError = () => {
  return {
    type: "DISMISS_ERROR",
  };
};