import { lazy, Suspense } from "react";
import { useSelector } from "react-redux";

import React from "react";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import { Spinner } from "reactstrap";

const OSA = lazy(() => import("./containers/OSA"));
const OrgChart = lazy(() => import("./containers/OrgChart"));
const Operational = lazy(() => import("./containers/Operational"));
const People = lazy(() => import("./containers/People"));
const EarlyWarn = lazy(() => import("./containers/EarlyWarn"));
const Questions = lazy(() => import("./containers/Questions"));
const ActionPlanning = lazy(() => import("./containers/ActionPlanning"));
const CreateActionPlan = lazy(() => import("./containers/CreateActionPlan"));
const NPS = lazy(() => import("./containers/NPS"));
const LazyOSA = (props) => {
  return (
    <Suspense
      fallback={
        <div
          className="content"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#19191A",
          }}
        >
          <Spinner
            style={{ width: "3rem", height: "3rem" }}
            type="grow"
            size="lg"
          />
        </div>
      }
    >
      <ErrorBoundary>
        <OSA {...props} />
      </ErrorBoundary>
    </Suspense>
  );
};

const LazyOrgChart = (props) => {
  return (
    <Suspense
      fallback={
        <div
          className="content"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#19191A",
          }}
        >
          <Spinner
            style={{ width: "3rem", height: "3rem" }}
            type="grow"
            size="lg"
          />
        </div>
      }
    >
      <ErrorBoundary>
        <OrgChart {...props} />
      </ErrorBoundary>
    </Suspense>
  );
};

const LazyOperational = (props) => {
  return (
    <Suspense
      fallback={
        <div
          className="content"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#19191A",
          }}
        >
          <Spinner
            style={{ width: "3rem", height: "3rem" }}
            type="grow"
            size="lg"
          />
        </div>
      }
    >
      <ErrorBoundary>
        <Operational {...props} />
      </ErrorBoundary>
    </Suspense>
  );
};

const LazyQuestions = (props) => {
  return (
    <Suspense
      fallback={
        <div
          className="content"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#19191A",
          }}
        >
          <Spinner
            style={{ width: "3rem", height: "3rem" }}
            type="grow"
            size="lg"
          />
        </div>
      }
    >
      <ErrorBoundary>
        <Questions {...props} />
      </ErrorBoundary>
    </Suspense>
  );
};

const LazyEarlyWarn = (props) => {
  return (
    <Suspense
      fallback={
        <div
          className="content"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#19191A",
          }}
        >
          <Spinner
            style={{ width: "3rem", height: "3rem" }}
            type="grow"
            size="lg"
          />
        </div>
      }
    >
      <ErrorBoundary>
        <EarlyWarn {...props} />
      </ErrorBoundary>
    </Suspense>
  );
};

const LazyPeople = (props) => {
  return (
    <Suspense
      fallback={
        <div
          className="content"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#19191A",
          }}
        >
          <Spinner
            style={{ width: "3rem", height: "3rem" }}
            type="grow"
            size="lg"
          />
        </div>
      }
    >
      <ErrorBoundary>
        <People {...props} />
      </ErrorBoundary>
    </Suspense>
  );
};
const LazyActionPlanning = (props) => {
  return (
    <Suspense
      fallback={
        <div
          className="content"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#19191A",
          }}
        >
          <Spinner
            style={{ width: "3rem", height: "3rem" }}
            type="grow"
            size="lg"
          />
        </div>
      }
    >
      <ErrorBoundary>
        <ActionPlanning {...props} />
      </ErrorBoundary>
    </Suspense>
  );
};
const LazyCreateActionPlan = (props) => {
  return (
    <Suspense
      fallback={
        <div
          className="content"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#19191A",
          }}
        >
          <Spinner
            style={{ width: "3rem", height: "3rem" }}
            type="grow"
            size="lg"
          />
        </div>
      }
    >
      <ErrorBoundary>
        <CreateActionPlan {...props} />
      </ErrorBoundary>
    </Suspense>
  );
};
const LazyNPS = (props) => {
  return (
    <Suspense
      fallback={
        <div
          className="content"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#19191A",
          }}
        >
          <Spinner
            style={{ width: "3rem", height: "3rem" }}
            type="grow"
            size="lg"
          />
        </div>
      }
    >
      <ErrorBoundary>
        <NPS {...props} />
      </ErrorBoundary>
    </Suspense>
  );
};

const routes = () => {
  return [
    {
      path: "/Operational",
      name: "Response Rate",
      component: LazyOperational,
      layout: "",
      icon: "fas fa-reply-all",
    },
    {
      path: "/OrgChart",
      name: "OrgChart",
      component: LazyOrgChart,
      layout: "",
      icon: "fas fa-sitemap",
    },
    {
      path: "/OSA",
      name: "OSA",
      component: LazyOSA,
      layout: "",
      icon: "tim-icons icon-world",
    },
    {
      path: "/People",
      name: "People",
      component: LazyPeople,
      layout: "",
      icon: "tim-icons icon-single-02",
    },
    {
      path: "/Questions",
      name: "Questions",
      component: LazyQuestions,
      layout: "",
      icon: "tim-icons icon-align-center",
    },
    {
      path: "/early-warn",
      name: "Early Warn",
      component: LazyEarlyWarn,
      layout: "",
      icon: "tim-icons icon-sound-wave",
    },
    {
      path: "/action-plan",
      name: "Action Planning",
      component: LazyActionPlanning,
      layout: "",
      icon: "tim-icons icon-sound-wave",
    },
    {
      path: "/create",
      name: "Create Action Plan",
      component: LazyCreateActionPlan,
      layout: "",
      icon: "tim-icons icon-sound-wave",
    },
    {
      path: "/nps",
      name: "NPS",
      component: LazyNPS,
      layout: "",
      icon: "fas fa-users",
    },
  ];
};

export default routes();
