const initialState = {
  isLoading: true,
  isLoadingOverAll: true,
  error: false,
  error_msg: null,
  filters: null,
  isLoadingFilters: true,
  selectedFilter: null,
  allFilters: [],
  cycleSentiments: null,
  selectedSurvey: null,
  selectedCycle: null,
  SentimentsByFilter: null,
};

export const osaReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_FILTER_START":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_FILTER_SUCCESS":
      return {
        ...state,
        filters: action.payload.data,
        selectedFilter: action.payload.selectedFilter,
        isLoading: false,
        error: false,
        error_msg: null,
      };
    case "FETCH_FILTER_FAILURE":
      return {
        ...state,
        isloading: false,
        error: true,
        error_msg: action.payload,
      };
    case "DISMISS_ERROR":
      return {
        ...state,
        error: false,
        error_msg: null,
      };
    case "FETCH_SENTIMENTS_BY_CYCLE_START":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_OVER_ALL_SENTIMENTS_ALL_CYCLES_START":
      return {
        ...state,
        isLoadingOverAll: true,
      };
    case "FETCH_SENTIMENTS_BY_CYCLE_START_FILTER":
      return {
        ...state,
        isLoadingFilters: true,
      };
    case "FETCH_SENTIMENTS_BY_CYCLE_SUCCESS":
      // Object.assign(state.data,);
      //
      return {
        ...state,
        isLoading: false,
        isLoadingFilters: false,
        SentimentsByFilter: action.payload.data,
      };
    case "FETCH_OVER_ALL_SENTIMENTS_ALL_CYCLES_SUCCESS":
      // Object.assign(state.data,);

      return {
        ...state,
        isLoadingOverAll: false,
        cycleSentiments: action.payload.data,
      };
    case "FETCH_SENTIMENTS_BY_CYCLE_FAILURE":
      return {
        ...state,
        isloading: false,
        error: true,
        error_msg: action.payload,
      };
    case "FETCH_OVER_ALL_SENTIMENTS_ALL_CYCLES_FAILURE":
      return {
        ...state,
        isLoadingOverAll: false,
        error: true,
        error_msg: action.payload,
      };
    case "SET_SELECTED_FILTER": //for Selected Group
      
      return {
        ...state,
        selectedFilter: action.payload.selectedGroup
          ? action.payload.selectedGroup
          : "city",
      };
    case "SET_OSA_FILTER":
      return {
        ...state,

        allFilters: action.payload.allfilters,
      };

    default:
      return state;
  }
};
