import { selectedSurvey } from "../actions/init";

const initialState = {
    isLoading: true,
    error: false,
    error_msg: null,
    user: null,
    company: null,
    surveys: null,
    selectedSurvey: null,
    selectedCycle: null,
  };
  
  export const initReducer = (state = initialState, action) => {
    switch (action.type) {
      case "FETCH_INIT_START":
        return {
          ...state,
          isLoading: true,
        };
      case "FETCH_INIT_SUCCESS":
        return {
          ...state,
          user: action.payload.user,
          company: action.payload.company,
          surveys: action.payload.surveys,
          selectedSurvey: action.payload.selectedSurvey,
          selectedCycle: action.payload.selectedCycle,
          isLoading: false,
          error: false,
          error_msg: null,
        };
      case "FETCH_INIT_FAILURE":
        return {
          ...state,
          isloading:false,
          error: true,
          error_msg: action.payload,
        };
      case "DISMISS_ERROR":
        return {
          ...state,
          error: false,
          error_msg: null,
        };
      case "SET_SELECTED_SURVEY":
        
        return {
          ...state,
          selectedSurvey: action.payload
        };
      case "SET_SELECTED_CYCLE":
        
        return {
          ...state,
          selectedCycle: action.payload
        };
  
      default:
        return state;
    }
  };
  