import React, { useEffect, useState } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Spinner } from "reactstrap";
import Cookies from "universal-cookie";
import { withRouter } from "react-router";
import { restoreToken } from "./store/actions/auth";
import { FetchInit } from "./store/actions/init";

import Admin from "./layouts/Admin/Admin";

import LoginContainer from "./containers/Login";

const cookies = new Cookies();

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  marginTop: "-50px",
  marginLeft: "-50px",
  width: "100px",
  height: "100px",
};
const App = (props) => {
  const [userToken, setUserToken] = useState(null);
  const [isAuthenticated, setAuthenticated] = useState(true);
  const { isLoading, categories, error, error_msg } = useSelector(
    (state) => state.init,
    shallowEqual
  );
  const Dispatch = useDispatch();
  useEffect(() => {
    const fetchToken = async () => {
      let userToken = await cookies.get("x-auth");
      try {
        userToken = (await cookies.get("x-auth"))
          ? await cookies.get("x-auth")
          : null;
        Dispatch(restoreToken(userToken));
        Dispatch(FetchInit());

        if (userToken == null) {
          setUserToken(userToken);
          // props.hist.push('/login');
          props.history.push("/login");
        }
        setAuthenticated(true);
      } catch (e) {
        // Restoring token failed
      }
    };
    fetchToken();
  }, [cookies.get("x-auth")]);
  if (userToken !== null && isLoading) {
    return (
      <div className="content" style={style}>
        <Spinner style={{ width: "5rem", height: "5rem" }} type="grow" />
      </div>
    );
  }
  // if (cookies.get('x-auth')!==undefined && error) {
  // 	return (
  // 	  <div  style={{
  // 		position: "absolute",
  // 		top: "50%",
  // 		left: "50%",
  // 		transform: "translate(-50%,-50%)",
  // 	  }}>
  // 		<h1>{error_msg}</h1>
  // 	  </div>
  // 	);
  //   }
  if (cookies.get("x-auth") === null) {
    <React.Fragment>
      <Route exact path="/Login" component={LoginContainer} />
      <Route
        exact
        strict
        path="/"
        render={({ location }) => {
          if (location.pathname === window.location.pathname) {
            return <Redirect to="/Login" />;
          }
          return null;
        }}
      />
    </React.Fragment>;
  }
  return (
    <Switch>
      <Route exact strict path="/login" component={LoginContainer} />
      <Route path="/" render={(props) => <Admin {...props} />} />
    </Switch>
  );
};

export default withRouter(App);
