const initialState = {
  isLoading: true,
  data: {},
  error: false,
  error_msg: null,
  allFilters: [],
};

export const npsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "DISMISS_ERROR":
      return {
        ...state,
        error: false,
        error_msg: null,
      };
    case "FETCH_NPS_START":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_NPS_SUCCESS":
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };
    case "FETCH_NPS_FAILURE":
      return {
        ...state,
        isLoading: false,
        error: true,
        error_msg: action.payload,
      };
    case "SET_NPS_FILTER":
      return {
        ...state,

        allFilters: action.payload.allfilters,
      };

    default:
      return state;
  }
};
