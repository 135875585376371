import Cookies from "universal-cookie";
const axios = require("axios");
// const API_URL = "https://dev.xane.ai";
let OLD_API_AUTH_URL, API_URL;
// if (process.env.NODE_ENV === "production") {
OLD_API_AUTH_URL = "https://api.xane.ai/r1";
API_URL = "https://api.surveys.xane.ai/v1";
// } else {
// OLD_API_AUTH_URL = "https://dev.xane.ai";
// API_URL = "https://api.surveys.xane.ai/dev";
// }

const cookies = new Cookies();

const headers = {
  "x-origin": 3,
  "x-version": 1,
  "Content-Type": "application/json",
};

export const requestApi = async (resourcePath, method, params) => {
  let url = API_URL + resourcePath;
  headers["x-auth"] = await cookies.get("x-auth");

  if (resourcePath == "/auths/login") {
    url = OLD_API_AUTH_URL + resourcePath;
    delete headers["x-auth"];
  }
  if (
    resourcePath.includes("/departments?") ||
    resourcePath.includes("/action-plans")
  ) {
    url = OLD_API_AUTH_URL + resourcePath;
  }

  if (["POST", "PUT", "DELETE"].indexOf(method) > -1 && params) {
    let response = await axios({ url, method, data: params, headers });
    return response.data;
  } else {
    let response = await axios({ url, method, headers });
    return response.data;
  }
};
