const initialState = {
  isLoading: true,
  error: false,
  error_msg: null,
  filters: null,
  orgChartData: null,
  surveys: null,
  selectedFilter: null,
  orgChartRoot: null,
};

export const orgChartReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_ORGCHART_FILTER_START":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_ORGCHART_FILTER_SUCCESS":
      return {
        ...state,
        filters: action.payload.data,
        selectedFilter: action.payload.selectedFilter,
        isLoading: false,
        error: false,
        error_msg: null,
      };
    case "FETCH_ORGCHART_FILTER_FAILURE":
      return {
        ...state,
        isloading: false,
        error: true,
        error_msg: action.payload,
      };
    case "DISMISS_ERROR":
      return {
        ...state,
        error: false,
        error_msg: null,
      };
    case "FETCH_ORGCHART_START":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_ORGCHART_ROOT_START":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_ORGCHART_SUCCESS":
      return {
        ...state,
        orgChartData: action.payload,
        isLoading: false,
        error: false,
        error_msg: null,
      };
    case "FETCH_ORGCHART_ROOT_SUCCESS":
      return {
        ...state,
        orgChartRoot: action.payload,
        isLoading: false,
        error: false,
        error_msg: null,
      };
    case "FETCH_ORGCHART_FAILURE":
      return {
        ...state,
        isloading: false,
        error: true,
        error_msg: action.payload,
      };
    case "FETCH_ORGCHART_ROOT_FAILURE":
      return {
        ...state,
        isloading: false,
        error: true,
        error_msg: action.payload,
      };
    case "SET_SELECTED_FILTER_ORG":
      return {
        ...state,
        selectedFilter: action.payload,
      };

    default:
      return state;
  }
};
