const initialState = {
  isLoading: true,
  isLoadingDepartment: true,
  isLoadingActionPlan: true,
  actionPlans: [],
  error: false,
  error_msg: null,
  departments: null,
};

export const actionPlanReducer = (state = initialState, action) => {
  switch (action.type) {
    case "DISMISS_ERROR":
      return {
        ...state,
        error: false,
        error_msg: null,
      };
    case "FETCH_DEPARTMENT_START":
      return {
        ...state,
        isLoadingDepartment: true,
      };
    case "FETCH_DEPARTMENT_SUCCESS":
      return {
        ...state,
        isLoadingDepartment: false,
        departments: action.payload,
      };
    case "FETCH_DEPARTMENT_FAILURE":
      return {
        ...state,
        isLoadingDepartment: false,
        error: true,
        error_msg: action.payload,
      };
    case "POST_ACTION_PLAN_START":
      return {
        ...state,
        isLoadingActionPlan: true,
      };
    case "POST_ACTION_PLAN_SUCCESS":
      return {
        ...state,
        isLoadingActionPlan: false,
        actionPlans: [...state.actionPlans, action.payload.action_plan],
      };
    case "POST_ACTION_PLAN_FAILURE":
      return {
        ...state,
        isLoadingActionPlan: false,
        error: true,
        error_msg: action.payload,
      };
    case "FETCH_ACTION_PLAN_START":
      return {
        ...state,
        isLoadingActionPlan: true,
      };
    case "FETCH_ACTION_PLAN_SUCCESS":
      return {
        ...state,
        isLoadingActionPlan: false,
        actionPlans: action.payload.action_plans,
      };
    case "FETCH_ACTION_PLAN_FAILURE":
      return {
        ...state,
        isLoadingActionPlan: false,
        error: true,
        error_msg: action.payload,
      };
    case "UPDATE_ACTION_PLAN_START":
      return {
        ...state,
        isLoadingActionPlan: true,
      };
    case "UPDATE_ACTION_PLAN_SUCCESS":
      return {
        ...state,
        isLoadingActionPlan: false,
        actionPlans: state.actionPlans.map((plan) => {
          if (plan.id === action.payload.action_plan.id) {
            return {
              ...plan,
              completed: action.payload.action_plan.completed,
            };
          } else {
            return plan;
          }
        }),
      };
    case "UPDATE_ACTION_PLAN_FAILURE":
      return {
        ...state,
        isLoadingActionPlan: false,
        error: true,
        error_msg: action.payload,
      };

    default:
      return state;
  }
};
