import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import thunk from "redux-thunk";

import { authReducer } from "./reducers/auth";
import { initReducer } from "./reducers/init";
import { orgChartReducer } from "./reducers/orgChart";
import { operationalReducer } from "./reducers/operational";
import { osaReducer } from "./reducers/osa";
import { questionsReducer } from "./reducers/questions";
import { peopleReducer } from "./reducers/people";
import { actionPlanReducer } from "./reducers/actionPlan";
import { npsReducer } from "./reducers/nps";

const rootReducer = combineReducers({
  auth: authReducer,
  init: initReducer,
  orgChart: orgChartReducer,
  operational: operationalReducer,
  osa: osaReducer,
  questions: questionsReducer,
  people: peopleReducer,
  actionPlan: actionPlanReducer,
  nps: npsReducer,
});

const composeEnhancers =
  (process.env.NODE_ENV === "development"
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : null) || compose;

export const configureStore = () => {
  return createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));
};
